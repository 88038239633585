import styled from '@emotion/styled';

export const GalleryWrapper = styled.div`
  figure {
    margin: 0 0 1.25rem;

    .image-wrapper {
      display: block;
      position: relative;
      margin: 0 0 .5rem;
      overflow: hidden;

      > div {
        transition: transform .5s ease;
      }

      img {
        width: 100%;
        height: auto;
        margin: 0;
      }

      button {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        border: none;
        outline: none;
        opacity: 0;
        font: 0/0 a;
        cursor: pointer;
        transition: opacity .5s ease;

        svg {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 20px;
          margin: -10px 0 0 -10px;
          fill: #FFFFFF;
        }

        &:hover {
          opacity: 1;
        }
      }

      &:hover {
        > div {
          transform: scale(1.05);
        }
      }
    }
  }
`;
