import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Shuffle from 'shufflejs';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { GalleryWrapper } from './Gallery.styled';

const Gallery = ({ images, category }) => {
  const shuffleContainer = React.useRef();
  const [shuffleInstance, setShuffleInstance] = React.useState(null);
  const [filteredImages, setFilteredImages] = React.useState(images);
  const [open, setOpen] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState(0);

  React.useEffect(() => {
    if (!shuffleInstance) {
      const shuffle = new Shuffle(shuffleContainer.current, {
        itemSelector: '.js-shuffle-item'
      });
      setShuffleInstance(shuffle);
    } else {
      shuffleInstance.filter(category);
      setFilteredImages(category && category !== 'all' ? images.filter(image => image.category === category) : images);
    }
  }, [shuffleInstance, images, category]);

  const closeModal = () => {
    document.documentElement.removeAttribute('style');
    setOpen(false);
  };

  const openModal = event => {
    setCurrentImage(filteredImages.findIndex(({ id }) => id === event.currentTarget.getAttribute('data-id')));
    document.documentElement.style.overflow = 'hidden';
    setOpen(true);
  };

  return (
    <>
      <GalleryWrapper>
        <div ref={shuffleContainer} className="row">
          {images.map(({ id, image, title, category }) => (
            <figure key={id} className="col-12 col-sm-6 col-lg-4 col-xl-3 js-shuffle-item" data-groups={`["${category}"]`}>
              <div className="image-wrapper">
                <GatsbyImage image={getImage(image)} alt={title} />
                <button data-id={id} onClick={openModal} onKeyDown={openModal}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
                  </svg>
                  Powiększ
                </button>
              </div>
            </figure>
          ))}
        </div>
      </GalleryWrapper>
      <ModalGateway>
        {open ? (
          <Modal onClose={closeModal}>
            <Carousel currentIndex={currentImage} views={filteredImages} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

export default Gallery;
